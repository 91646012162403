import type { Holiday } from "mkm-types";

interface MessageCondition {
  condition: (holiday: Holiday, weeksToGo: number, monthsToGo: number) => boolean;
  message: (weeksToGo: number, monthsToGo: number) => string;
}

const messageConditions: MessageCondition[] = [
  // Customer not spent and more than 75% of time is left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 75 && holiday.percent_target_reached === 0,
    message: (weeksToGo: number, monthsToGo: number) => ` ${monthsToGo} months to go!\nStart your holiday experience!`,
  },
  // customer has spent some with more than 75% of time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 75 &&
      holiday.percent_target_reached > 0 &&
      holiday.percent_target_reached < 25,
    message: (weeksToGo: number, monthsToGo: number) =>
      `${monthsToGo} months to go!\nKeep working, your experience awaits!`,
  },
  // Customer has spent above 75% with +75% of time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 75 &&
      holiday.percent_target_reached > 75 &&
      holiday.percent_target_reached < 100,
    message: (weeksToGo: number, monthsToGo: number) =>
      `${monthsToGo} months to go!\nAlmost done, your experience is near!`,
  },
  // Customer has spent between 25% & 75% with 75% of time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 75 &&
      holiday.percent_target_reached >= 25 &&
      holiday.percent_target_reached <= 75,
    message: (weeksToGo: number, monthsToGo: number) =>
      `${monthsToGo} months to go!\nKeep going to unlock your experience!`,
  },
  // customer has spent between 25% & 75% with 75% to 10% of time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 10 &&
      holiday.percent_scheme_time_left <= 75 &&
      holiday.percent_target_reached >= 25 &&
      holiday.percent_target_reached <= 75,
    message: (weeksToGo: number, monthsToGo: number) =>
      `${monthsToGo} months to go!\nKeep going to unlock your experience!`,
  },
  // customer has spent +75% but less than their target with 6 weeks or less to go
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left > 0 &&
      holiday.percent_scheme_time_left <= 10 &&
      holiday.percent_target_reached >= 75 &&
      holiday.percent_target_reached < 100,
    message: (weeksToGo: number) => `${weeksToGo} weeks to go!\nAlmost done, your experience is near!`,
  },
  // Customer has met their target with some or no time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left >= 0 && holiday.percent_target_reached === 100,
    message: () => "Down tools, your experience is secured!",
  },
  // customer failed to meet their target with no time left
  {
    condition: (holiday: Holiday): boolean =>
      holiday.percent_scheme_time_left === 0 && holiday.percent_target_reached < 100,
    message: () => "Spend period has ended!",
  },
];

/**
 * Gets an appropriate holiday message based on progress and time remaining
 * Messages are evaluated in the order defined in the messageConditions array
 */
export function getHolidayMessage(holiday: Holiday, weeksToGo: number, monthsToGo: number): string {
  if (!holiday?.destination) {
    return "Holiday";
  }

  const defaultHolidayMessage = `${Math.max(0, monthsToGo)} months to go! Start your holiday experience!`;
  const matchingCondition = messageConditions.find(({ condition }) => condition(holiday, weeksToGo, monthsToGo));

  return matchingCondition ? matchingCondition.message(weeksToGo, monthsToGo) : defaultHolidayMessage;
}
