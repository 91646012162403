<template>
  <Stack class="w-full" data-test-id="holidaySchemeContainer" data-fs="holidaySchemeContainer">
    <SkeletonLoader v-if="loadingStatement" class="h-[303px]" />
    <SimpleCard v-if="!loadingStatement && displayHolidayScheme" class="mt-2xs" slot-class="rounded-sm !p-[18px]">
      <Stack direction="col" gap="2xs" class="w-full">
        <Stack align="center" justify="center" class="w-full">
          <Stack gap="2xs" direction="row" justify="center" align="center">
            <Heading tag="h1" class="!text-lg !leading-lg !font-bold">Your</Heading>
            <SvgIcon name="LogoMKMLight" :width="52" :height="15" />
            <Heading tag="h1" class="!text-lg !leading-lg !font-bold">Experience</Heading>
          </Stack>
        </Stack>

        <Stack direction="col" gap="2xs" class="w-full">
          <Stack direction="col" gap="3xs" class="w-full">
            <Stack align="center" gap="sm" justify="between" class="w-full m-3xs">
              <SvgIcon name="takeOff" :height="24" :width="20" />
              <Stack gap="3xs" align="center">
                <Text tag="span" weight="bold" size="xs" data-test-id="holidaySchemeDestination">
                  {{ holidayDestination }}
                </Text>
                <SvgIcon name="sun" :width="19" :height="24" />
              </Stack>
            </Stack>

            <div
              class="relative w-full h-4 bg-mkm-blue-default rounded-md overflow-hidden h-xs"
              data-test-id="holidaySchemeProgessBar"
            >
              <div
                class="h-full absolute top-0 bottom-0 left-0 bg-mkm-yellow-default transition-all rounded-r-md duration-300 ease-in-out"
                :style="{ width: percentTargetReached }"
              />
            </div>
          </Stack>

          <Stack direction="col" gap="3xs" class="w-full pb-xs border-b-sm border-grey-light">
            <Stack justify="between" direction="row" class="w-full">
              <Stack direction="col" gap="3xs" v-if="formattedSpend">
                <span align="center">
                  <Text tag="span" size="sm" weight="semi" class="text-grey-darker" align="center">Current Spend</Text>
                  <Heading
                    tag="h2"
                    class="!text-xl !leading-xl"
                    align="center"
                    data-test-id="holidaySchemeCurrentSpend"
                  >
                    {{ formattedSpend }}
                  </Heading>
                </span>
              </Stack>

              <Stack direction="col" gap="3xs" v-if="formattedSpendTarget">
                <span align="center">
                  <Text tag="span" size="sm" weight="semi" class="text-grey-darker" align="right">Target</Text>
                  <Heading tag="h2" class="!text-xl !leading-xl" align="right" data-test-id="holidaySchemeTargetSpend">
                    {{ formattedSpendTarget }}
                  </Heading>
                </span>
              </Stack>
            </Stack>
          </Stack>

          <Stack align="center" justify="between" class="w-full pt-2xs">
            <Heading
              tag="h3"
              class="!text-md !leading-md whitespace-pre-line"
              align="center"
              data-test-id="holidaySchemeMessage"
            >
              {{ holidayMessage }}
            </Heading>
          </Stack>
        </Stack>
      </Stack>
    </SimpleCard>
  </Stack>
</template>

<script lang="ts" setup>
import { toCurrency } from "mkm-avengers";
import { getHolidayMessage } from "~/helpers/holidayScheme/holidayMessages";
import dayjs from "dayjs";

const { accountSummary, loadingStatement, displayHolidayScheme } = useStatement();

const holidayDestination = computed(() => accountSummary?.value?.holiday?.destination || "");

const formattedSpend = computed(() =>
  accountSummary?.value?.holiday?.spend === undefined
    ? ""
    : toCurrency({ value: accountSummary.value.holiday.spend }).replace(/\.00$/, ""),
);

const formattedSpendTarget = computed(() =>
  accountSummary?.value?.holiday?.spend_target === undefined
    ? ""
    : toCurrency({ value: accountSummary.value.holiday.spend_target }).replace(/\.00$/, ""),
);

const percentTargetReached = computed(() =>
  accountSummary?.value?.holiday?.percent_target_reached === undefined
    ? "0%"
    : `${accountSummary.value.holiday.percent_target_reached}%`,
);

/**
 * Calculates the number of months remaining until the scheme end date
 */
const monthsToGo = computed(() => {
  const schemeEndDate = accountSummary?.value?.holiday?.scheme_end_date;
  if (!schemeEndDate) return 0;

  try {
    const endDate = dayjs(schemeEndDate);
    if (!endDate.isValid()) return 0;

    const today = dayjs();
    const months = endDate.diff(today, "month");

    return Math.max(0, months);
  } catch {
    return 0;
  }
});

/**
 * Calculates the number of weeks remaining until the scheme end date
 */
const weeksToGo = computed(() => {
  const schemeEndDate = accountSummary?.value?.holiday?.scheme_end_date;
  if (!schemeEndDate) return 0;

  try {
    const endDate = dayjs(schemeEndDate);
    if (!endDate.isValid()) return 0;

    const today = dayjs();
    const weeks = endDate.diff(today, "week");

    return Math.max(0, weeks);
  } catch {
    return 0;
  }
});

/**
 * Generates the appropriate message to show the user
 * based on their current progress and time remaining
 */
const holidayMessage = computed(() => {
  const holiday = accountSummary?.value?.holiday;
  if (!holiday) return "";

  return getHolidayMessage(holiday, weeksToGo.value, monthsToGo.value);
});
</script>
