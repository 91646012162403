<template>
  <Stack direction="col" gap="none" class="w-full relative" v-on-click-outside="closeDropdown">
    <SkeletonLoader v-if="!displayMenu" :display-logo="false" class="h-xl px-sm py-xs">
      <Stack align="center" justify="between" class="w-full">
        <Stack class="w-1/3 h-xs bg-grey-default rounded-sm" />
        <Icon name="chevron-down" :size="16" :color="getColor('grey-default')" />
      </Stack>
    </SkeletonLoader>

    <Stack
      v-if="displayMenu"
      align="center"
      justify="between"
      class="w-full py-[9px] px-[10px] bg-white border-sm border-grey-default rounded-sm cursor-pointer"
      @click="toggleDropdown"
    >
      <Text tag="span">{{ activeMenuItem }}</Text>
      <span :class="['transition-transform duration-200', isExpanded && 'rotate-180']">
        <Icon name="chevron-down" :size="16" :color="getColor('mkm-blue-light')" />
      </span>
    </Stack>

    <Dropdown v-if="displayMenu" :active="isExpanded" class="w-full absolute z-50 !shadow-all-sm mt-xl">
      <aside class="w-full bg-white py-2xs" data-test-id="myAccountMenu-Mobile">
        <nav>
          <ul>
            <li
              role="none"
              class="h-full w-full bg-white hover:bg-grey-default"
              v-for="menuItem in items"
              :key="menuItem.key"
              @click="toggleDropdown"
            >
              <NuxtLink :to="menuItem.href" class="block w-full h-full px-sm py-[12px]" role="menuitem">
                <Text tag="span" :weight="route.path === menuItem.href ? 'bold' : 'semi'" align="left">
                  {{ menuItem.label }}
                </Text>
              </NuxtLink>
            </li>
            <li v-if="!hiddenSignOut" class="h-full w-full bg-white hover:bg-grey-default" role="none">
              <button class="block w-full h-full px-sm py-[12px] text-left" @click="handleSignOut" role="menuitem">
                <Text tag="span" weight="semi" class="text-red-dark" align="left">Sign out</Text>
              </button>
            </li>
          </ul>
        </nav>
      </aside>
    </Dropdown>

    <MobileAppPromo
      v-if="displayPromo"
      src="/account/mobile-mobile-promotion.png"
      alt="Manage Your Credit Account"
      class="mt-sm"
    />

    <RewardsClub v-if="isAccountDashboard" class="mt-sm" />

    <HolidayScheme v-if="isAccountDashboard" class="mt-sm" />
  </Stack>
</template>

<script lang="ts" setup>
import { MobileMenuProps } from "./mobileMenuTypes";
import { MyAccountMenuItem } from "~/components/MyAccount/MyAccountMenu/myAccountMenuTypes";
import { getColor } from "mkm-avengers";
import { toRefs } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { ROUTES } from "~/helpers/routes/routes";

const props = defineProps<MobileMenuProps>();
const { items, hiddenSignOut } = toRefs(props);

const { logout } = useUser();
const route = useRoute();

const activeMenuItem = computed(() => {
  const menuItem: MyAccountMenuItem | undefined = items.value.find((menuItem) => menuItem.href === route.path);

  if (!menuItem) {
    const parentPath = route.path
      .split("/")
      .slice(0, route.path.split("/").length - 1)
      .join("/");

    const item = items.value.find((menuItem) => parentPath === menuItem.href);

    return item?.label;
  }

  return menuItem ? menuItem.label : null;
});

const displayMenu = computed(() => items.value.length > 0);
const displayPromo = computed(() => route.path.includes("financial"));
const isAccountDashboard = computed(() => route.path === ROUTES.ACCOUNT_DASHBOARD);

const isExpanded = ref(false);

const toggleDropdown = () => {
  isExpanded.value = !isExpanded.value;
};

const closeDropdown = () => {
  isExpanded.value = false;
};

const handleSignOut = async () => {
  await logout();
};
</script>
