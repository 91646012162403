<template>
  <Stack class="w-full">
    <SkeletonLoader v-if="loadingStatement" class="h-[303px]" />
    <Stack
      v-if="!loadingStatement && accountSummary && accountSummary.rewards && displayRewardsClub"
      direction="col"
      gap="none"
      class="w-full"
      data-test-id="rewardsClubContainer"
      data-fs="rewardsClubContainer"
    >
      <Stack align="end" gap="3xs">
        <Heading tag="h2" class="!text-2xl !leading-2xl text-mkm-blue-default">Rewards</Heading>
        <Heading tag="h2" class="!text-3xl !leading-3xl text-mkm-yellow-default" display-font uppercase>Club</Heading>
      </Stack>
      <SimpleCard class="mt-2xs" slot-class="rounded-sm !p-[18px]">
        <Stack direction="col" gap="2xs">
          <Stack direction="col" gap="3xs" class="w-full">
            <Text tag="span" size="sm" weight="semi">Qualifying Spend</Text>
            <Heading tag="h2" class="!text-2xl !leading-2xl" data-test-id="rewardsClubQualifySpend">
              {{ toCurrency({ value: accountSummary?.rewards.spend }) }}
            </Heading>
          </Stack>
          <Stack direction="col" gap="3xs" class="w-full pb-xs border-b-sm border-grey-light">
            <Text tag="span" size="sm" weight="semi">Next Target</Text>
            <Stack v-if="accountSummary?.rewards.status === 'M'" align="center" gap="2xs">
              <SvgIcon name="check-circle" :width="18" :height="18" :color="getColor('green-dark')" />
              <Text size="sm" weight="semibold" class="text-green-dark">Targets reached</Text>
            </Stack>
            <Heading v-else tag="h2" class="!text-2xl !leading-2xl" data-test-id="rewardsClubTargetSpend">
              {{ toCurrency({ value: accountSummary?.rewards.next_target }) }}
            </Heading>
          </Stack>
          <Stack align="center" justify="between" class="w-full pt-2xs">
            <Stack><Text tag="span">Rewards available</Text></Stack>
            <Stack>
              <Text tag="span" weight="semibold" data-test-id="rewardsClubAvailableRewards">
                {{ toCurrency({ value: accountSummary?.rewards.earned }) }}
              </Text>
            </Stack>
          </Stack>
          <Stack class="w-full">
            <NuxtLink class="w-full" to="https://www.mkmrewardsclub.co.uk" target="_blank">
              <Button size="md" variant="secondary" class="!w-full" data-test-id="myRewardsBtn" data-fs="myRewardsBtn"
                >My Rewards Club</Button
              >
            </NuxtLink>
          </Stack>
        </Stack>
      </SimpleCard>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { getColor, toCurrency } from "mkm-avengers";

const { accountSummary, displayRewardsClub, loadingStatement } = useStatement();
</script>
