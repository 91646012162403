<template>
  <Stack direction="col" class="w-full !gap-[10px]">
    <img :src="src" :alt="alt" :img-attrs="{ class: 'w-full' }" />
    <Stack align="center" justify="center" class="w-full !gap-[10px]">
      <NuxtLink to="https://play.google.com/store/apps/details?id=com.mkm.mkmmobile" target="_blank">
        <NuxtPicture
          src="/logos/google-play.svg"
          alt="Google Play"
          :width="isMobile ? '203px' : '149px'"
          :height="isMobile ? '60px' : '44px'"
        />
        <span class="sr-only">Download the MKM Mobile App for Android.</span>
      </NuxtLink>
      <NuxtLink to="https://apps.apple.com/gb/app/mkm/id6450894143" target="_blank">
        <NuxtPicture
          src="/logos/app-store.svg"
          alt="App Store"
          :width="isMobile ? '179px' : '131px'"
          :height="isMobile ? '60px' : '44px'"
        />
        <span class="sr-only">Download the MKM Mobile App for iOS.</span>
      </NuxtLink>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { MobileAppPromoProps } from "./mobileAppPromoTypes";

const { src, alt } = defineProps<MobileAppPromoProps>();

const { width } = useWindowSize();
const isMobile = computed(() => width.value < 1024);
</script>
